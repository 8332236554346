.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  min-height: calc(20vh - var(--app-header-height));
  margin-top: 10px;
  padding: 16px;
}

.text {
  max-width: 50%;
  margin-bottom: 8px;

  text-align: center;

  color: var(--dark-64);
}

.subtext {
  margin-bottom: 16px;

  text-align: center;

  color: var(--dark-32);
}

.button {
  max-width: fit-content;

  white-space: nowrap;
}

.containerPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 10;
  justify-content: center;

  height: 80vh;
}

.pageImage {
  margin-bottom: 32px;
}

.buttonPage {
  width: 300px;
  margin-top: 32px;
}

.textPage {
  margin-top: 16px;
}

.errorButton {
  width: 100% !important;
}

@media (max-width: 540px) {
  .text {
    max-width: 100% !important;
  }
}
