.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 16px;
  margin-bottom: 8px;
  gap: 8px;
}

.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 12px;
  background-color: var(--black-75);
}

.pageActive {
  background-color: var(--blue-ribbon);
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 8px 0;
  margin-bottom: 48px;
  gap: 4px;

  cursor: pointer;
  user-select: none;
}

.loaderContainer {
  padding: 4px;

  cursor: wait;

  border-radius: 50%;
  background-color: var(--martinique);
}

.iconMore {
  width: 16px;
  height: 16px;
}
