
/* .moreInfo {
  position: relative;
} */

.dots {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  cursor: pointer;

  border-radius: 4px;
  background-color: var(--white-10);
  gap: 2px;
}

.dots:hover {
  background-color: var(--white-32);
}

.activeInfo {
  background-color: var(--white-32);
}

.dot {
  width: 2px;
  height: 2px;

  border-radius: 1px;
  background-color: var(--white);
}

.list {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 48px;

  visibility: hidden;

  width: fit-content;
  min-width: 144px;
}

.list::-webkit-scrollbar {
  display: none;

  width: 0;
}

.listOpen {
  visibility: visible;
}
