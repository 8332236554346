.container {
  --event-margin: 12px;
  --event-card-gap: 12px;
  --event-avatar-size: 48px;
  --event-card-left-margin: calc(var(--event-avatar-size) + var(--event-margin));

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
