.lolProfileCard {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  width: 100%;
}

@media screen and (min-width: 540px) {
  .lolProfileCard {
    overflow-x: hidden;
  }
}
