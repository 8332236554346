.container {
  position: relative;

  display: flex;
  justify-content: space-between;

  background-color: var(----color-background);
}

.containerHover {
  background-color: var(--black-5) !important;
}

.wrapper {
  display: flex;
}

.highlighted {
  background-color: rgba(255, 190, 0, 0.1) !important;

  transition: background-color 0.2s;
}

.body {
  /* max-width: 650px; */
  width: 100%;
  margin-left: 10px;
  /* padding-right: 26px; */

  word-break: break-word;
}

.authorContainer {
  display: flex;
  align-items: center;
}

.forwardedAuthorContainer {
  /* P2 */
  font-size: 13px;
  line-height: 16px;

  display: flex;
  align-items: center;

  margin-bottom: 8px;

  color: var(--black-40);
}

.forwardedAuthor {
  padding-left: 8px;
}

.author {
  cursor: pointer;

  color: var(--white);
}

.time {
  margin-left: 8px;

  cursor: default;

  color: var(--dark-32);
}

.views {
  margin-left: 8px;
}

.avatar {
  cursor: pointer;
}

.photo {
  display: block;

  margin-top: 8px;

  cursor: pointer;

  border: 1px solid var(--black-5);
  border-radius: 8px;
}

.gif {
  margin-top: 8px;

  border: 1px solid var(--black-5);
  border-radius: 8px;
}

.newText {
  font-size: inherit; /* 14px */
  line-height: inherit; /* 18px */

  display: flex;

  white-space: pre-line;

  color: var(--dark-64);
}

.reactionsContainer {
  display: inline-flex;
}

.threadContainer {
  display: flex;
  align-items: center;

  margin-top: 8px;

  cursor: pointer;
}

.threadRepliesCount {
  font-size: 15px;
  font-weight: 500;

  padding-left: 8px;

  user-select: none;
}

.hashtag {
  cursor: pointer;

  color: var(--red);
}

.mention {
  background-color: var(--yellow-20);
}

.mentionMe {
  color: var(--white);
  background-color: var(--yellow-48);
}

.mention,
.mentionMe {
  font-weight: 500;

  padding: 0 3px;

  cursor: pointer;

  border-radius: 2px;
}

.url {
  cursor: pointer;
  text-decoration: underline !important;
  word-break: break-all;

  color: var(--green) !important;
}

.quotedForwardedWrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: 8px 0;
}

.quotedForwardedBorder {
  padding: 0 8px 0 12px;

  border-left: 2px solid var(--green);
}

.inlineCode {
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 85%;

  width: auto;
  height: auto;
  margin: -0.2em 0;
  padding: 0.2em;

  text-indent: 0;

  border: none;
  border-radius: 3px;
  background-color: var(--white-10);
}

.blockCode {
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 0.75rem;

  box-sizing: border-box;
  max-width: 90%;
  margin-top: 6px;
  padding: 0;

  white-space: pre-wrap;

  border-width: 2px;
  border-style: solid;
  border-color: var(--black-10);
  border-radius: 5px;
  background-color: var(--black-5);
}

.gifContainer {
  position: relative;
}

.gifWatermark {
  position: absolute;
  right: 8px;
  bottom: 4px;
}

.actions {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 12px;

  display: none;
  align-items: center;
  justify-content: flex-end;

  width: 40px;
  height: 100%;
}

.replyIcon {
  width: 20px;
  height: 20px;
}

.container:hover > .actions {
  display: flex;
}

.container:hover > .actions > .replyIcon {
  opacity: 0.7;
}

.container:hover > .actions > .moreActions > .moreActionsIcon {
  opacity: 0.7;
}

.replyIcon,
.moreActionsIcon {
  opacity: 0;
}

.replyIcon:hover,
.moreActionsIcon:hover {
  opacity: 1 !important;
}

.moreActions,
.replyIcon {
  display: flex;
  align-items: center;

  box-sizing: content-box;
  height: 50%;
  padding-left: 10px;

  cursor: pointer;
  text-align: right;
}

.moreActions:hover > .actionsPopup {
  display: block;
}

.moreActions:hover > .moreActionsIcon {
  opacity: 1 !important;
}

.actionsPopup {
  position: absolute;
  right: 0;

  display: block;
}

.onlineUser {
  display: flex;

  width: 5px;
  height: 5px;
  margin-top: 1px;
  margin-left: 3px;

  border-radius: 50%;
  background-color: var(--green);
}

/* Channel  */
.channelContainer {
  position: relative;

  display: flex;
  justify-content: space-between;

  margin: 16px 0;
  padding: 8px 72px;

  background-color: var(--white);
}

@media (max-width: 500px) {
  .channelContainer {
    padding: 4px 16px;
  }
}
/* .channelContainer:hover {
background-color: var(--black-5);
} */
.channelContainer:hover > .actions {
  display: flex;
}

.channelContainer:hover > .actions > .replyIcon {
  opacity: 0.6;
}

.channelContainer:hover > .actions > .moreActions > .moreActionsIcon {
  opacity: 0.6;
}

.channelBody {
  width: 100%;
  max-width: 881px;

  word-break: break-word;
}

.channelThumbnail {
  display: flex;
  align-items: center;

  margin-bottom: 8px;
}

.channelSubheader {
  display: flex;

  margin-top: 4px;
}

.channelAuthor {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-left: 12px; */
}

.channelName {
  font-size: 13px;
  /* P2 */
  font-weight: bold;
  line-height: 16px;
}

.channelTime {
  font-size: 13px;
  line-height: 16px;

  cursor: default;
  /* P2 */
  color: var(--black-30);
}

.channelPhotosContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.channelPhoto {
  max-width: 100%;

  cursor: pointer;

  border: 1px solid var(--black-5);
  border-radius: 6px;
  background-color: var(--black-10);
}

.channelMessageDivider {
  margin: 0 72px;

  border: 0;
  border-bottom: 1px solid var(--black-5);
}

.newThread {
  display: flex;
  justify-content: space-between;

  height: 24px;
  margin-top: 12px;
}

.threadCounts {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.threadCountsHeading {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

  margin-left: 6px;

  color: var(--black-30);
}

.likeCounts {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.likeCountsNumber {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;

  margin-right: 6px;

  user-select: none;
}

.like > * {
  transition: fill 0.15s, stroke 0.19s;
}

.email {
  color: var(--white);
}

.blurredText {
  display: inline;

  width: fit-content;

  color: var(--dark-08) !important;
  border-radius: 4px;
  background-color: var(--dark-08);
}

.blurredText:hover {
  color: var(--dark-64) !important;
  background-color: transparent;
}

.blurredText::selection {
  color: var(--dark-08) !important;
}

.moreButton {
  cursor: pointer;

  color: var(--white) !important;
  border: none;
  background-color: transparent;
}

.moreButton:hover {
  color: var(--green);
}

.linkContainer {
  text-decoration: underline;

  color: var(--black-100);
}