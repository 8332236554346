.list {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: calc(50% - 180px);

  display: flex;
  flex-direction: column-reverse;

  width: 100%;
  max-width: 360px;
  height: fit-content;
  padding-top: 16px;
}

.container {
  display: flex;
  align-items: center;

  width: 100%;
  margin-top: 8px;
  padding: 8px;

  cursor: grabbing;

  opacity: 0;
  color: var(--black);
  border-radius: 12px;
  background-color: var(--green);
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.48);

  transition: opacity 150ms ease, transform 150ms ease;
  transform: translateY(-30px);
}

.container > * {
  color: var(--dark);
}

.containerVisible {
  opacity: 1;

  transform: translateY(0);
}

.error {
  background-color: var(--red);

  /* transform-origin: 50% 50%; */
  /* animation-name: spaceboots;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: 2; */
}

.warning {
  background-color: var(--yellow);
}

.iconWrapper {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  padding: 10px;

  border-radius: 6px;
  background-color: var(--dark);
}

@keyframes spaceboots {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0) rotate(1deg);
  }

  30% {
    transform: translate(0, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
