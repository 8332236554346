.container {
  display: flex;
  align-items: center;

  margin-bottom: var(--event-margin);
}

.avatarImg {
  width: var(--event-avatar-size);
  height: var(--event-avatar-size);
  margin-right: var(--event-margin);

  border-radius: 50%;
}

.thumbnailContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  margin-right: var(--event-margin);
}

.usernameContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  width: fit-content;
  margin-right: 4px;
}

.streamerCheck {
  margin-left: 4px;
}

.usernameText:hover {
  color: var(--white-80);
}

.thumbnailDetailsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  width: fit-content;
  margin-top: 4px;
  gap: 8px;
}

.actionsContainer {
  display: flex;
  gap: 4px;
}

.discordContainer {
  display: flex;
  align-items: center;

  cursor: pointer;

  background-color: transparent;
}

.discordIcon {
  --icon-color: var(--dark-48);
  user-select: none;
}

.discordText {
  margin: 0 2px 0 4px;

  user-select: none;

  color: var(--dark-48);
}

.discordContainer:hover > .discordIcon {
  --icon-color: var(--dark-64);
}

.discordContainer:hover > .discordText {
  color: var(--dark-64);
}

.discordContainer:active > .discordIcon {
  --icon-color: var(--white);
  opacity: 0.7;
}

.discordContainer:active > .discordText {
  opacity: 0.7;
  color: var(--white);
}

.separator {
  width: 3px;
  height: 3px;

  user-select: none;

  border-radius: 50%;
  background-color: var(--dark-20);
}

.buttonFollowIcon {
  stroke: var(--white);
  fill: var(--white);
}

.buttonIsFollowingIcon {
  stroke: var(--dark-48);
  fill: var(--dark-48);
}

@media screen and (max-width: 540px) {
  .container {
    overflow: hidden;
  }
}
