.container {
  position: relative;

  display: flex;
  flex-shrink: 0;

  /* width: fit-content;
  height: fit-content; */

  border-radius: 50%;
  background-color: var(--dark-48);
}

.onlineContainer {
  position: absolute;
  right: 8.33%;
  bottom: 8.33%;

  width: 20%;
  height: 20%;

  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--color-wrapper-bg);
  background-color: var(--green);
}

.offline {
  visibility: hidden;
}

.image {
  width: inherit;
  height: inherit;

  border: inherit;
  border-radius: 50%;
  background-size: cover;
  background-color: var(--green-20);
}

.emptyAvatar {
  font-weight: 500;
  line-height: 1em;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  user-select: none;
  text-align: center;
  text-transform: uppercase;

  color: var(--white);
  border-radius: 50%;
}

.editable {
  background-color: rgb(36, 242, 77, 0.32);
}

.editable > svg {
  stroke: var(--main-green);
}

.imageAvatar {
  position: absolute;
  top: 0;
  left: 0;

  width: inherit;
  height: inherit;

  border-radius: inherit;
  background-size: cover;
}

.listContainer {
  display: flex;
}

.listContainer > * {
  width: 48px;
  height: 48px;
}

.listContainer > *:not(:first-child) {
  margin-left: -16px;
}

.linkContainer {
  cursor: pointer;
}

.avatarContainer {
  width: 48px;
  height: 48px;
}

/* @media (max-width: 540px) {
  .onlineContainer {
    width: 12% !important;
    height: 12% !important;
  }
} */
