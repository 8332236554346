.container {
  line-height: inherit;

  display: flex;
  align-items: center;

  color: inherit;
}

.icon {
  width: 20px;
  height: 1em;
  margin-right: 2px;

  object-fit: cover;
}

.text {
  line-height: inherit;

  color: inherit;
}
