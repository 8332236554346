.container {
  position: relative;

  padding: 9px 12px;

  border-radius: 6px;
  background-color: var(--dark-next, var(--dark-12));

  transition: background-color 100ms ease;
}

.container > textarea {
  max-height: 450px;
  margin-top: 16px;

  resize: vertical;
}

.inputContainer {
  height: 48px;
}

.containerWithIcon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerFocused {
  /*  */
}

.label {
  position: absolute;
  z-index: 2;
  top: 9px;
  left: 12px;

  cursor: text;
  user-select: none;
  pointer-events: none;

  color: var(--dark-48);

  transition: transform 40ms linear, font-size 40ms ease;
  transform: translateY(7px);
}

.labelSmall {
  cursor: unset;

  transform: translateY(0);
}

.input {
  flex-grow: 1;

  width: 100%;
  padding-top: 14px;

  text-overflow: ellipsis;

  color: var(--white);
  border: none;
  outline: none;
  background-color: transparent;
}

.textArea {
  margin-top: 16px;
  padding: 0 !important;
  padding-top: 0;
}

.inputWithIcon {
  padding-top: 0;
}

.input::placeholder {
  opacity: 0;

  transition: opacity 150ms ease;
}

.input:focus::placeholder {
  opacity: 1;
}

.input:disabled {
  cursor: not-allowed;
}

.inputWithIcon::placeholder {
  opacity: 1;
}

.inputError {
  margin-top: 2px;

  color: var(--red);
}

.iconContainer {
  width: 24px;
  height: 24px;

  border-radius: 4px;
  background-color: var(--white);
}

.inputIcon {
  width: 24px;
  height: 24px;
  fill: var(--dark);
  stroke: var(--dark);
}

.labelError {
  color: var(--yellow);
}

.labelRequired:after {
  content: '*';

  color: var(--main-green);
}
