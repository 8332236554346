.wrapper {
  --event-card-padding: 16px;
  --event-card-gap: 12px;
  display: flex;
  flex-direction: column;

  max-width: 100%;
  min-height: 48px;
}

.wrapperPadded {
  margin-left: var(--event-card-left-margin);
}

.container {
  z-index: 3;

  max-width: 100%;
  min-height: 48px;

  border-radius: 16px;
  background-color: var(--dark-12);
  box-shadow: var(--inset-shadow);
}

.container:not(:only-child) {
  border-bottom: 1px solid #ffffff06;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.postfixContainer {
  z-index: 1;

  padding: var(--event-card-padding);

  border-radius: 0 0 16px 16px;
  background-color: #ffffff03;
}
