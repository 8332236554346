.container {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 320px;
  min-height: 300px;
  max-height: 700px;

  border-radius: 16px;
  background-color: var(--dark-next, var(--dark-12));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), inset 0 1px 0 rgba(255, 255, 255, 0.04);
}

.topContainer {
  position: relative;

  flex-shrink: 0;

  height: 100%;
  max-height: 180px;

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.wrapper {
  --dark-next: var(--dark-32);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 20px;
}
