
.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 100%;
  margin-bottom: 16px;

  color: var(--dark-64);
}

.text {
  max-width: 100%;
}

.clampedText {
  display: -webkit-box;
  overflow: hidden;

  -webkit-line-clamp: 16;
  -webkit-box-orient: vertical;
}

.showMoreButton {
  cursor: pointer;
  white-space: nowrap;

  color: var(--main-green) !important;
}
