.container {
  position: sticky;
  z-index: var(--header-z);
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: var(--app-content-width);
  height: var(--app-header-height);
}

.backgroundContainer {
  background-color: var(--color-background);
}

.avatarImg {
  width: 32px;
  height: 32px;
}

.pageTitleText {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;

  padding-right: var(--app-right-section-gap);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightSectionContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  max-width: var(--app-right-section-width);
  margin-left: var(--app-right-section-gap);

  gap: 8px;
}

.actionButton {
  flex-grow: 1;
}
