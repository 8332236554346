.container {
  --icon-color: var(--white);

  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;

  height: 48px;
  padding: 4px 8px;

  cursor: pointer;

  color: var(--icon-color);
  border-radius: 8px;
  background-color: var(--dark-next-2, var(--dark-12));
}

.container:hover {
  --icon-color: var(--dark-64);
}

.container:active {
  --icon-color: var(--dark-32);
}

.green20Container {
  --icon-color: var(--green);
  background-color: var(--green-20);
}

.green20Container:hover {
  --icon-color: var(--white-80);
}

.green20Container:active {
  --icon-color: var(--white);
}

.yellow20Container {
  --icon-color: var(--yellow);
  background-color: var(--yellow-20);
}

.yellow20Container:hover {
  --icon-color: var(--white-80);
}

.yellow20Container:active {
  --icon-color: var(--white);
}

.icon {
  width: 20px;
  height: 20px;
}

.text {
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;

  margin-top: 2px;

  text-align: center;

  color: inherit;
}

.container:disabled,
.container:disabled:hover {
  --icon-color: var(--dark-32);
  cursor: not-allowed;

  background-color: var(--dark-next-2, var(--dark-12));
}
