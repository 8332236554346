.lolChampionsContainer {
  /* position: relative; */

  display: flex;
}

.lolChampionAvatar {
  border-radius: 50%;
  background-color: var(--dark-12);
  background-size: cover;
  box-shadow: 0 0 0 2px var(--dark-12);
}

.lolChampionAvatar > svg {
  width: 18px;
  height: 18px;
}
