.container {
  --event-horizontal-padding: 16px;
  display: flex;
  flex-direction: column;

  padding: 16px 0;

  border-radius: 16px;
  background: var(--dark-08);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04);
}

.thumbnailContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
}

.thumbailWrapper {
  display: flex;
  align-items: center;

  margin-right: 8px;
}

.thumbnailLink {
  display: flex;
  align-items: center;
}

.thumbnailAvatar {
  flex-shrink: 0;

  width: 32px;
  height: 32px;
}

.thumbnailAuthorName {
  overflow: hidden;

  margin-left: 6px;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.thumbnailVerifiedIcon {
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.thumbnailGameIcon {
  flex-shrink: 0;

  width: 24px;
  height: 24px;

  border-radius: 4px;
}

.calendarContainer {
  display: flex;
  align-items: center;

  margin-bottom: 6px;
  padding: 0 var(--event-horizontal-padding);
}

.calendarIcon {
  --icon-color: var(--yellow);
  width: 16px;
  height: 16px;
}

.calendarText {
  margin-left: 6px;

  color: var(--yellow);
}

.titleContainer {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;

  display: inline-block;

  margin-bottom: 4px;
  padding: 0 var(--event-horizontal-padding);
}

.descriptionContainer {
  margin-bottom: 8px;
  padding: 0 var(--event-horizontal-padding);
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
  gap: 8px;
}

.previewContainer {
  --preview-width: calc(100vw - 2 * var(--event-horizontal-padding));
  --preview-height: calc(var(--preview-width) * 9 / 16);

  position: relative;

  display: flex;
  flex-direction: column;

  width: var(--preview-width);
  height: var(--preview-height);
  margin: 0 var(--event-horizontal-padding);
  margin-bottom: 12px;
}

.previewImage {
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  /* background-color: rgba(255, 255, 255, 0.08); */
  object-fit: cover;
}

.membersContainer {
  display: flex;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
}

.avatarListWrapper {
  position: relative;
}

.membersText {
  /* margin-left: 6px; */

  color: var(--dark-64);
}

.actionsContainer {
  display: flex;
  flex-grow: 1;

  gap: 8px;

  padding: 0 var(--event-horizontal-padding);
}

.descriptionPostMessage {
  color: var(--white) !important;
}
