.container {
  display: flex;

  max-width: 100%;
  padding: 16px 20px;

  border-radius: 16px;
  background: var(--dark-next, var(--dark-08));
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04);
}

.sideContainer {
  flex-shrink: 0;

  margin-right: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: calc(100% - 12px - 48px);
  margin-top: 2px;
}

.thumbnailContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
}

.thumbailWrapper {
  display: flex;
  align-items: center;

  margin-right: 40px;
}

.thumbnailLink {
  display: flex;
  align-items: center;
}

.thumbnailAvatar {
  flex-shrink: 0;

  width: 48px;
  height: 48px;
}

.thumbnailAuthorName {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.thumbnailVerifiedIcon {
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.thumbnailGameIcon {
  position: absolute;
  top: 0;
  right: 0;

  flex-shrink: 0;

  width: 32px;
  height: 32px;

  border-radius: 6px;
}

.calendarContainer {
  display: flex;
  align-items: center;

  margin-bottom: 6px;
  padding: 0 var(--event-horizontal-padding);
}

.calendarIcon {
  --icon-color: var(--yellow);
  width: 16px;
  height: 16px;
}

.calendarText {
  margin-left: 6px;

  color: var(--yellow);
}

.titleContainer {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;

  display: inline-block;

  margin-bottom: 8px;
  padding: 0 var(--event-horizontal-padding);
}

.descriptionContainer {
  margin-bottom: 8px;
  padding: 0 var(--event-horizontal-padding);
}

.descriptionText {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.descriptionPostMessage {
  color: var(--white) !important;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
  gap: 8px;
}

.previewContainer {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 309px;
  margin: 0 var(--event-horizontal-padding);
  margin-bottom: 12px;
}

.previewImage {
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  /* background-color: rgba(255, 255, 255, 0.08); */
  object-fit: cover;
}

.membersContainer {
  display: flex;

  margin-bottom: 12px;
  padding: 0 var(--event-horizontal-padding);
}

.avatarListWrapper {
  position: relative;
}

.membersText {
  /* margin-left: 6px; */

  color: var(--dark-64);
}

.actionsContainer {
  display: flex;
  flex-grow: 1;

  cursor: pointer;

  gap: 8px;
}
