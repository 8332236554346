
.container {
  --icon-color: var(--green);
  display: flex;
  align-items: center;

  color: var(--green);
}

.containerPast {
  --icon-color: var(--yellow);
  color: var(--yellow);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.text {
  color: inherit;
}

.smallText {
  font-size: 12px !important;
  line-height: 14px !important;
}
