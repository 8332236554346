.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 4px;

  border-radius: 6px;

  transition: background-color 200ms ease;
}

.containerError {
  background-color: var(--red-70);
}

.indexWrapper {
  display: flex;
  justify-content: space-between;

  margin-bottom: 8px;
}

.indexText {
  font-size: 11px;
  line-height: 12px;

  user-select: none;

  color: var(--dark-32);
}

.indexButtonsContainer {
  display: flex;

  margin-left: 8px;

  gap: 8px;
}

.indexButton {
  width: 12px;
  height: 12px;

  cursor: pointer;

  background-color: transparent;
}

.indexButton:disabled {
  cursor: not-allowed;
}

.indexButton > svg {
  width: inherit;
  height: inherit;
  stroke: var(--dark-32);

  transition: stroke 150ms ease;
}

.indexButton:hover > svg {
  stroke: var(--dark-48);
}

.indexButton:active > svg {
  stroke: var(--dark-64);
}

.indexButton:disabled > svg {
  stroke: var(--dark-20);
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: 6px;
  gap: 1px;
}

.titleWrapper {
  position: relative;

  display: flex;

  border-radius: inherit;
}

.iconContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  margin-right: 1px;

  user-select: none;
  pointer-events: none;

  border-top-left-radius: inherit;
  background-color: var(--dark-12);
}

.iconImage {
  width: 24px;
  height: 24px;
}

.iconLoader {
  position: absolute;

  visibility: hidden;

  background-color: inherit;

  transition: visibility 200ms ease;
}

.iconLoaderVisible {
  visibility: visible;
}

.input {
  flex-grow: 1;

  height: 40px;
  padding: 10px 12px;

  color: var(--white);
  background-color: var(--dark-12);
}

.titleWrapper > .input {
  border-top-right-radius: inherit;
}

.wrapper > .input:last-of-type {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.invalidInput {
  color: var(--red);

  animation: shake 0.75s ease-in-out;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-2px);
  }

  20%,
  80% {
    transform: translateX(4px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-8px);
  }

  40%,
  60% {
    transform: translateX(8px);
  }
}

.deleteIconTemp {
  position: absolute;
  top: 10px;
  right: 12px;

  width: 20px;
  height: 20px;

  cursor: pointer;
  stroke: var(--dark-64);

  transition: stroke 150ms ease;
}

.deleteIconTemp:hover {
  stroke: var(--red);
}

.loadingContainer {
  position: absolute;
  z-index: 2;

  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: wait;

  border-radius: inherit;
  background-color: #0005;
}

.loadingVisibleContainer {
  visibility: visible;
}
