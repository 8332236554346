.rounded {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  border-radius: 50%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, var(--white-10) 100%);
}

.rounded > * {
  width: 20px;
  height: 20px;
}

.roundedSmall {
  width: 20px;
  height: 20px;
}

.roundedSmall > * {
  width: 12px;
  height: 12px;
}
