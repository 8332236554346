.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  opacity: 0;
  background-color: var(--overlay);

  transition: visibility 150ms, opacity 150ms linear;
}

.overlayOpen {
  visibility: visible;

  opacity: 1;
}

.modal {
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  min-width: 250px;
  min-height: 250px;
  padding: 0;

  border: none;
  border-radius: 20px;
  background-color: var(--dark-06);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.05);

  transition: transform 200ms ease;
  transform: translateY(10px);
}

.modalOpen {
  transform: translateY(0);
}

.modalWrapper {
  /* padding: 32px 24px 24px; */
  height: 100%;
}

.closeIcon {
  position: absolute;
  z-index: 2000;
  top: 20px;
  right: 20px;

  width: 32px !important;
  height: 32px !important;

  cursor: pointer;

  transition: fill 150ms ease;
}

.confirmationText {
  line-height: 1.5;

  margin-bottom: 32px;

  text-align: center;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.buttonContainer:not(:last-child) {
  margin-right: 8px;
}

.confirmOverlay {
  z-index: 5000;
}

.confirmContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 300px;
  min-height: 100px;
  padding: 24px 32px;
}
