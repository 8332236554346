
.listContainer {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;

  width: fit-content;
  height: fit-content;

  cursor: pointer;

  border: none;
  /* border-end-end-radius: 10px;
  border-end-start-radius: 10px; */
  border-radius: 8px;
  background-color: var(--dark-20);
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.16), inset 0 1px 0 rgba(255, 255, 255, 0.05); */
  backdrop-filter: blur(30px);
}

.listItem:hover {
  background-color: var(--dark-32);
}

.paginationContainer {
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  height: 100px;

  background-color: none;
}
