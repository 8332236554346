.container {
  display: flex;
  overflow-y: clip;

  width: 100%;
  max-width: calc(var(--app-content-width) + var(--app-navigation-width));
}

.navigationContainer {
  position: sticky;
  z-index: 2;
  top: 0;

  /* width: 100%; */
  width: var(--app-navigation-width);
  height: 100vh;
  padding: 24px;
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: var(--app-content-width);
}
