.scrollButton {
  z-index: 100;

  display: flex;
  align-items: center;

  padding: 20px 40px;
  padding-left: 0;

  cursor: pointer;

  border: none;
  background: none;

  transition: opacity 0.2s ease-in-out, visibility 0.5s ease-in-out;
  gap: 4px;
}

.scrollButtonText {
  color: var(--dark-64);
}

.scrollButtonIcon {
  stroke: var(--dark-64);
}

.scrollButton:hover .scrollButtonText {
  color: var(--white);
}

.scrollButton:hover .scrollButtonIcon {
  stroke: var(--white);
}

@media (max-width: 540px) {
  .scrollButton {
    position: fixed;
    top: 20px;
    left: 20px;

    align-items: center;
    flex-direction: row-reverse;

    width: fit-content;
    padding: 14px;

    border-radius: 50px;
    background-color: rgb(0 0 0 / 64%);
  }

  .scrollButtonIcon {
    width: 24px;
    height: 24px;
  }

  .scrollButtonText {
    display: none;
  }
}
