.wowTableWrapper {
  width: 100%;
}

.lostArkTableWrapper {
  width: 100%;
}

.mobileShadow {
  display: none;
}

.wowTable {
  width: 100%;

  border-spacing: 0;
}

.wildriftTable {
  width: 100%;

  border-spacing: 0;
}

.wowTable > tbody > tr:not(:last-child) > td {
  padding-bottom: 10px;

  border-bottom: 1px solid var(--dark-20);
}

.wowTable > tbody > tr:not(:first-child):not(:nth-child(2)) > td {
  padding-top: 10px;
}

.wowTable > tbody > tr > td:first-child {
  width: 30%;
}

.wowTable > tbody > tr > td:not(:first-child) {
  width: 14%;
}

.firstColumnData {
  display: flex;

  width: 250px;
}

.lostArkTable {
  width: 100%;

  border-spacing: 0;
}

.lostArkTable > tbody > tr:not(:last-child) > td {
  padding-bottom: 11px;

  border-bottom: 1px solid var(--dark-20);
}

.lostArkTable > tbody > tr:not(:first-child):not(:nth-child(2)) > td {
  padding-top: 11px;
}

.lostArkTable > tbody > tr:not(:first-child):not(:last-child):not(:nth-child(2)) > td {
  min-height: 45px;
}

.lostArkTable > tbody > tr:nth-child(2) > td {
  min-height: 35px;
}

.lostArkTableData {
  align-items: center;

  color: var(--dark-64);
}

.buildsList {
  display: flex;
  gap: 16px;
}

.build {
  width: fit-content;

  cursor: pointer;

  border-bottom: 1px dotted var(--dark-32);
}

.build:hover {
  color: var(--white);
  border-bottom: none;
}

.tableLabel {
  font-weight: 400;

  margin-bottom: 6px;

  text-align: start;

  color: var(--dark-48);
}

.tableData {
  align-items: center;

  min-height: 22px;
  margin-right: 4px;

  white-space: nowrap;

  color: var(--dark-64);
}

.mainColumn {
  display: flex;
  align-items: center;
}

.copyIconWrapper {
  display: flex;
  justify-content: flex-end;

  width: 100%;
}

.copyIcon {
  width: 12px;
  height: 12px;
  stroke: var(--dark-48);

  cursor: pointer;
}

.copyIcon:hover {
  stroke: var(--white);
}

.wowAvatar {
  width: 20px;
  height: 20px;

  border-radius: 4px !important;
}

.wowImageContainer {
  width: 20px;
  height: 20px;
  margin-right: 8px;

  border-radius: 4px;
}

.wowImage {
  width: 20px;
  height: 20px;

  border-radius: 4px;
}

.title {
  color: var(--dark-48);
}

.lostArkGamesList {
  display: grid;

  margin-top: 6px;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.lostArkGame {
  display: flex;
  gap: 8px;

  align-items: center;
}

.lostArkGameInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.lostArkGameInfoLine {
  display: flex;
  align-items: center;
  gap: 4px;
}

.lostArkGameInfoClass {
  color: var(--dark-48);
}

.classIcon {
  width: 32px;
  height: 32px;
}

.classIconTable {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.whiteText {
  color: var(--white);
}

.wildriftChampions {
  position: relative;

  display: flex;

  width: 100%;
  height: 28px;
}

.championIconWrapper {
  width: 32px;
  height: 32px;

  border-radius: 50%;
  outline: 2px solid var(--dark-12);
  background-size: cover;
}

.championImage {
  width: 100%;
  height: 100%;

  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 50%;
}

.preferredLine1 {
  stroke: var(--main-green);
  fill: var(--main-green);
}

.preferredLine2 {
  opacity: 0.48;
}

.roleWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.winrate {
  color: var(--main-green);
}

.aliasDataWrapper {
  width: 85%;
}

.aliasData {
  overflow-x: hidden;

  text-overflow: ellipsis;
}

.aliasLabel {
  cursor: pointer;
}

.lolCardContainer {
  display: flex;

  margin-bottom: 12px;
  padding-bottom: 16px;

  border-bottom: 1px solid var(--dark-20);
  gap: 18px;
}

@media (max-width: 1899px) {
  .lolCardContainer {
    gap: 12px;
  }
}

.lolChampionsContainer {
  /* position: relative; */

  display: flex;
}

.lolChampionAvatar {
  /* position: absolute; */
  /*
  width: 32px !important;
  height: 32px !important; */

  border: 2px solid var(--dark-12);
  border-radius: 50%;
  background-color: var(--dark-12);
  background-size: cover;
}

.lolChampionAvatar > svg {
  width: 18px;
  height: 18px;
}

.paramWrapper {
  flex-shrink: 0;
}

.paramContainer {
  margin-bottom: 2px;

  color: var(--dark-32);
}

.paramBody {
  display: flex;
  align-items: center;

  height: 14px;
  gap: 2px;
}

.dotWrapper {
  width: 3px;
  height: 3px;
  margin: 0 2px;

  border-radius: 50%;
  background-color: var(--dark-32);
}

.paramValueText {
  color: var(--dark-64);
}

.lolWinRateWinText {
  color: #2578f5;
}

.lolWinRateLoseText {
  color: #c5306e;
}

.winrateSmall {
  display: flex;
  align-items: center;
  gap: 2px;
}

.lolRankLabelContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.lolTierIcon {
  width: 20px;
  height: 20px;
}

.moreContainer {
  display: flex;
  align-items: center;

  margin-top: 6px;
  padding-top: 10px;

  cursor: pointer;

  border-top: 1px solid var(--dark-20);
  gap: 8px;
}

.moreText {
  color: var(--dark-48);

  transition: color 150ms ease;
}

.moreContainer:hover > .moreText {
  color: var(--dark-64);
}

.lostArkGameAvatar {
  border-radius: 8px;
}

.dot {
  width: 3px;
  height: 3px;

  border-radius: 50%;
  background-color: var(--dark-48);
}

@media (max-width: 540px) {
  .wowTableWrapper {
    overflow-y: hidden;
  }

  .lostArkTableWrapper {
    overflow-y: hidden;
  }

  .mobileShadow {
    position: absolute;
    top: 0;
    right: 0;

    display: block;

    width: 51px;
    height: 100%;

    border-radius: 16px;
    background: linear-gradient(259.44deg, #232323 8.73%, rgba(35, 35, 35, 0) 93.14%);
  }

  .mobileProfileShadow {
    width: 62px !important;

    background: linear-gradient(259.44deg, #161616 8.73%, rgba(35, 35, 35, 0) 93.14%);
  }

  .firstColumnData {
    width: fit-content;
    margin-right: 12px;
  }

  .copyIconWrapper {
    padding-right: 20px;
  }

  .tableLabel {
    margin-right: 32px;
  }

  .tableData {
    margin-right: 32px;
  }

  .lostArkTableData {
    margin-right: 32px;
  }

  .copyIcon {
    width: 16px;
    height: 16px;
  }

  .lostArkGamesList {
    display: grid;

    margin-top: 6px;
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
}
