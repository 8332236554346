
.container {
  display: flex;
  gap: 6px;

  flex-wrap: wrap;
}

.tagWrapper {
  padding: 4px 8px;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  pointer-events: none;

  border-radius: 4px;
  background-color: var(--dark-20);
}

.tagIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;
}
