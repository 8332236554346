.logo {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.logo:hover {
  filter: contrast(1.15);
}

.logo:active {
  opacity: 0.8;
}

.logoText {
  margin-left: 8px;
}
