.list {
  display: flex;
}

.container {
  font-size: inherit;
  line-height: inherit;

  color: var(--green);
}

.container:not(:last-of-type):after {
  display: inline-block;

  width: 1px;
  margin-right: 8px;
  margin-left: 8px;

  content: '/';
}
