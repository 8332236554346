
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  /* padding-right: var(--app-content-list-right-padding);
  padding-left: var(--app-content-list-left-padding); */
}

.profileListContainer {
  gap: 0 !important;
}

.container {
  display: flex;
  flex-direction: column;

  padding: var(--event-card-padding);

  border-radius: inherit;
}

.container:not(:first-child) {
  /* If there is a .topContainer above, top padding is smaller */
  padding-top: 12px;
}

.container:not(:last-child) {
  /* If there is a .bottomContainer below, bottomContainer padding covers  */
  padding-bottom: 0;
}

.topContainer {
  position: relative;

  display: flex;
  align-items: center;

  padding: var(--event-card-padding);
}

.topContainer:not(:only-child) {
  padding-bottom: 12px;

  border-bottom: 1px solid var(--dark-20);
}

.profileTopContainer > * {
  display: flex;
  flex-wrap: wrap;
}

.profileTopContainer:not(:only-child) {
  padding-bottom: 0 !important;

  border: none !important;
}

.bottomContainer {
  position: relative;

  display: flex;
  align-items: center;

  padding: var(--event-card-padding);
}

.profileBottomContainer {
  align-items: flex-start;
  flex-direction: column;

  gap: 8px;
}

.profileHeaderContainer {
  display: flex;
  align-items: center;

  margin-bottom: 12px;
}

.profileHeaderIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;

  pointer-events: none;
}

.emptyProfileCard {
  display: flex;
  align-items: center;

  padding: 17px;

  border-radius: 16px;
  background-color: var(--dark-12);
  gap: 12px;
}

.noUserProfileCard {
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  padding: 17px;

  background-color: var(--dark-08);

  /* border-radius: 16px; */
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.04);
}

.noUserProfileCard:first-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.noUserProfileCard:last-child {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.arrowsWrapper {
  display: flex;
  align-items: center;

  height: 100%;
  margin: auto;
  margin-right: 0 !important;
  gap: 4px;
}

.arrowDown:first-child {
  margin-left: 8px;
}

.arrowDown {
  min-width: 20px;
  height: 100%;
  min-height: 20px;

  cursor: pointer;

  background-color: var(--dark-08) !important;
  box-shadow: none !important;
}

.arrowDownIcon {
  stroke: var(--dark-48);

  width: 20px;
  height: 20px;
}

.editIcon {
  width: 16px;
  height: 16px;
}

.emptyProfileHeaderIcon {
  width: 32px;
  height: 32px;
}

.profileCardContent {
  display: flex;
  flex-direction: column;
}

.visibleProfileCardContent {
  display: flex;
  align-items: center;
  gap: 12px;

  width: 100%;
}

.hiddenProfileCardContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
  height: fit-content;

  transition: max-height 0.1s ease-in-out, opacity 0.3s ease-in-out, margin-top 0.2s ease-in-out;
}

.description {
  margin: 0 !important;
}

@media screen and (max-width: 540px) {
  .listContainer {
    padding-top: 20px;
  }

  .profileListContainer {
    padding-top: 0 !important;
  }
}
