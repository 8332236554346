.wrapper {
  flex-grow: 1;
}

.scrolledWrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.wrapper {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.wrapper::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.container {
  /* width: 100%; */
  /* table-layout: fixed; */
  border-spacing: 0;
}

.withPadding {
  margin-left: clamp(8px, 16px, 28px);
}

.container > tbody > tr > th > * {
  width: fit-content;
  margin-right: 16px;
}

.container > tbody > tr:not(:last-child) > td {
  padding-bottom: 11px;

  border-bottom: 1px solid var(--dark-20);
}

.container > tbody > tr:not(:first-child):not(:nth-child(2)) > td {
  padding-top: 11px;
}

.container > tbody > tr:not(:first-child):not(:last-child):not(:nth-child(2)) > td {
  min-height: 45px;
}

.container > tbody > tr:last-child > td {
  min-height: 35px;
}

.container > tbody > tr > td > * {
  width: fit-content;
  margin-right: 16px;
}

.tableLabel {
  margin-bottom: 2px;

  text-align: start;
  white-space: nowrap;

  color: var(--dark-32);
}

.tableData {
  white-space: nowrap;

  gap: 4px;

  color: var(--dark-64);
}

.tableMultiData {
  display: flex;
  align-items: center;
  gap: 4px;
}

.dot {
  flex-shrink: 0;

  width: 3px;
  height: 3px;

  user-select: none;

  border-radius: 50%;
  background-color: var(--dark-32);
}

.tableDataWrapper {
  display: flex;
  gap: 4px;

  align-items: center;
}

.tableDataWrapper:hover > .copyIcon {
  opacity: 1;
}

.tableDataWrapper:hover > .tableData {
  color: var(--white);
}

.copyIcon {
  width: 14px;
  height: 14px;

  opacity: 0.64;
}

.cardParams {
  display: flex;
  gap: 6px;
}

.cardParam {
  display: flex;
  align-items: center;

  white-space: nowrap;

  color: var(--dark-48);
}

.cardParam:not(:last-child):after {
  font-size: 15px;

  display: block;

  width: 2px;
  height: 2px;
  margin-left: 6px;

  content: '';
  text-align: center;

  background-color: var(--dark-48);
}
