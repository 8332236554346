
.container {
  font-size: inherit;
  line-height: inherit;

  display: flex;
  align-items: center;

  color: inherit;
  gap: inherit;
}

.text,
.winText,
.loseText {
  font-size: inherit;
  line-height: inherit;

  color: inherit;
}

.winText {
  color: #2578f5;
}

.loseText {
  color: #c5306e;
}

.dot {
  width: 3px;
  height: 3px;
  margin: 0 2px;

  border-radius: 50%;
  background-color: var(--dark-32);
}

.smallContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}
