.container {
  display: flex;
  align-items: center;

  padding: 10px;

  border-radius: 12px;
  background-color: var(--black-50);
}

.wrapper {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
  align-items: center;
  flex-grow: 1;
}

.wrapper > * {
  margin-right: 16px;
}

.avatarContainer {
  width: 40px;
  height: 40px;
}

.userLink {
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
}

.userName {
  overflow: hidden;

  text-overflow: ellipsis;
}

.searchCard {
  padding: 8px;

  border-radius: 20px;
  background-color: var(--black-75);
}

.searchCardGames {
  margin-top: 16px;
}

.searchCardGames > * {
  margin-bottom: 4px !important;
}

.searchCardGames > *:last-of-type {
  margin-bottom: 0 !important;
}

/* pasha user card */

.userCardContainer {
  display: flex;
  flex-direction: column;
}

.infoContainer {
  display: flex;
  align-items: center;

  margin-bottom: 16px;
}

.secondaryInfo {
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 16px;

  border-bottom: 1px solid var(--white-5);
}

.lastContainer {
  border-bottom: none;
}

.titlesContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.titlesNotificationContainer {
  padding-bottom: 16px;

  border-bottom: 1px solid;
  border-bottom-color: var(--white-5);
}

.cardAvatarContainer {
  position: relative;

  margin-right: 12px;
  padding-bottom: 16px;
}

.notificationcardAvatarContainer {
  padding-bottom: 16px;
}

.avatar {
  width: 48px;
  height: 48px;
}

.statContainer {
  display: flex;
  gap: 12px;

  margin-top: 2px;
}

.statOption {
  color: var(--white-48);
}

.userDescription {
  margin-bottom: 12px;

  color: var(--white-48);
}

.userGamesContainer {
  display: flex;
  gap: 12px;

  flex-wrap: wrap;
}

.userNotificationCardContainer {
  display: flex;
  flex-direction: column;
}

.mainNotificationCardInfo {
  display: flex;
  gap: 7px;
}

.infoNotificationContainer {
  display: flex;
  align-items: center;
}

.ratingNotificationBadget {
  margin-right: 5px;
}

.notificationBadget {
  position: absolute;
  right: -2px;
  bottom: 14px;
}

.buttonIsFollowingWithText {
  color: var(--dark-64) !important;
  stroke: var(--dark-64) !important;

  background-color: var(--dark-20) !important;
}

.buttonFollowWithText {
  color: var(--dark) !important;
  border-radius: 8px !important;
  stroke: var(--dark) !important;
}

.buttonFollowIcon {
  stroke: var(--white);
  fill: var(--white);
}

.buttonIsFollowingIcon {
  stroke: var(--dark-48);
  fill: var(--dark-48);
}

.buttonChildren {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  display: flex;
  align-items: center;
  flex-direction: column;

  color: inherit;
  stroke: inherit;
  gap: 2px;
}

.labelClassName {
  font-size: 10px;
  line-height: 12px;
}

.followButtonIcon {
  stroke: inherit;
}

.usernameWrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;

  width: fit-content;
}

.streamerCheck {
  min-width: 14px;
  min-height: 14px;
}
